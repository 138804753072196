import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, database, route, auth) {
        var _this = this;
        this.router = router;
        this.database = database;
        this.route = route;
        this.auth = auth;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                if (!event.urlAfterRedirects.startsWith('/admin')) {
                    window.gtag('config', 'UA-117928097-1', {
                        'page_path': event.urlAfterRedirects
                    });
                }
            }
        });
        this.auth.authState.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (user === null) {
                    console.log('user is null');
                }
                else {
                    this.database.collection('customers').doc(user.uid).valueChanges().subscribe(function (customer) {
                        if (customer) {
                            window.gtag('event', 'login', { method: 'E-Mail' });
                        }
                    });
                }
                return [2 /*return*/];
            });
        }); });
    }
    AppComponent.prototype.ngOnInit = function () {
    };
    return AppComponent;
}());
export { AppComponent };
