import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../../services/user.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(afAuth, afs, router, userService) {
        this.afAuth = afAuth;
        this.afs = afs;
        this.router = router;
        this.userService = userService;
        this.errors = [];
    }
    LoginComponent.prototype.handleFailedAuthError = function (error) {
        switch (error.code) {
            case "auth/wrong-password": return "Incorrect email or password.";
            default: return error.message;
        }
    };
    LoginComponent.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, employee, customer, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errors = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)];
                    case 2:
                        user = (_a.sent()).user;
                        return [4 /*yield*/, this.afs.collection('employees').doc(user.uid).ref.get()];
                    case 3:
                        employee = _a.sent();
                        if (employee.exists) {
                            if (employee.data().admin) {
                                this.router.navigate(['/admin']);
                            }
                            else {
                                this.router.navigate(['/staff']);
                            }
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.afs.collection('customers').doc(user.uid).ref.get()];
                    case 4:
                        customer = _a.sent();
                        if (customer.exists && (customer.data().status == "abandoned" || customer.data().status == "hidden") && this.userService.isInServiceArea(customer)) {
                            this.router.navigate(['/signup/step2']);
                            return [2 /*return*/];
                        }
                        this.router.navigate(['/settings']);
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        this.errors.push(this.handleFailedAuthError(error_1));
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
    };
    return LoginComponent;
}());
export { LoginComponent };
