import * as tslib_1 from "tslib";
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { flatMap } from 'rxjs/operators';
import { tap, take } from 'rxjs/operators';
var AdminGuard = /** @class */ (function () {
    function AdminGuard(af, afs, router) {
        this.af = af;
        this.afs = afs;
        this.router = router;
    }
    // TODO: key this off of a flag in users instead of a match pattern
    AdminGuard.prototype.canActivate = function () {
        var _this = this;
        return this.isAdministrator().pipe(tap(function (admin) {
            if (!admin) {
                _this.router.navigate(['/']);
            }
        }));
    };
    AdminGuard.prototype.isAdministrator = function () {
        var _this = this;
        return this.af.authState.pipe(take(1), flatMap(function (val) { return _this.getAdminFlag(val); }));
    };
    ;
    AdminGuard.prototype.getAdminFlag = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userRecord;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user)
                            return [2 /*return*/, false];
                        return [4 /*yield*/, this.afs.collection('employees').doc(user.uid).ref.get()];
                    case 1:
                        userRecord = _a.sent();
                        if (!userRecord.exists)
                            return [2 /*return*/, false];
                        return [2 /*return*/, userRecord.data().admin];
                }
            });
        });
    };
    AdminGuard.prototype.canLoad = function () {
        return this.isAdministrator();
    };
    return AdminGuard;
}());
export { AdminGuard };
