import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var ConfigService = /** @class */ (function () {
    function ConfigService() {
    }
    ConfigService.prototype.getScents = function () {
        return Object.keys(environment.scents).map(function (k) {
            return { key: k, value: k, children: Object.keys(environment.scents[k]).map(function (ck) {
                    return { key: ck, value: environment.scents[k][ck] };
                }) };
        });
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
