import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// TODO: Properly implement provider pattern for this
function getWindow() {
    return window;
}
var RecoverComponent = /** @class */ (function () {
    function RecoverComponent(afa) {
        this.afa = afa;
        this.email = "";
    }
    RecoverComponent.prototype.ngOnInit = function () {
    };
    RecoverComponent.prototype.sendPasswordReset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var window, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        window = getWindow();
                        url = window.location.protocol + "//" + window.location.host + "/auth/login";
                        return [4 /*yield*/, this.afa.auth.sendPasswordResetEmail(this.email, {
                                url: url,
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return RecoverComponent;
}());
export { RecoverComponent };
