import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';
var EditOrderComponent = /** @class */ (function () {
    function EditOrderComponent(afa, afs, aff, route, config, userService, router) {
        this.afa = afa;
        this.afs = afs;
        this.aff = aff;
        this.route = route;
        this.config = config;
        this.userService = userService;
        this.router = router;
        this.pickupDay = 0;
        this.binArray = [];
        this.order = {};
        this.orderId = null;
        this.canChange = null; // null triggers loading message
        this.customer = null;
    }
    EditOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe(function (customer) {
            if (!customer)
                return;
            if (customer.status == "abandoned" || customer.status == "hidden") {
                if (_this.userService.isInServiceArea(customer)) {
                    _this.router.navigate(['/signup/step2']);
                }
                else {
                    _this.router.navigate(['signup', 'coming-soon']);
                }
            }
            if (!customer.scent) {
                customer.scent = "None";
            }
            if (!customer.waterTemp) {
                customer.waterTemp = "Cold";
            }
            _this.customer = customer;
            _this.binArray = Array.from(new Array(customer.bincount), function (val, index) { return index + 1; });
        });
        this.route.params.subscribe(function (params) {
            _this.afs.collection('orders').doc(params['id']).valueChanges().subscribe(function (order) {
                var editCutoff = new Date();
                editCutoff.setHours(20);
                editCutoff.setMinutes(0);
                editCutoff.setSeconds(0);
                _this.order = order;
                _this.orderId = params['id'];
                if (order.date.toDate() > editCutoff) {
                    _this.canChange = true;
                    _this.pickupDays = _this.getOnDemandDays();
                }
                else {
                    _this.canChange = false;
                    _this.pickupDays = [
                        {
                            date: order.date.toDate(),
                            label: moment(order.date.toDate()).format("dddd, MMMM Do")
                        }
                    ];
                    _this.pickupDay = 0;
                }
            });
        });
    };
    EditOrderComponent.prototype.getOnDemandDays = function () {
        var today = moment().hour(20).minute(0).second(0); // today at 8pm
        var days = [];
        var nextDay = moment(today);
        while (days.length < 5) {
            nextDay.add(1, 'days');
            while (nextDay.day() < 1 || nextDay.day() > 4) {
                nextDay.add(1, 'days');
            }
            days.push({
                date: nextDay.toDate(),
                label: nextDay.format("dddd, MMMM Do")
            });
            if (nextDay.toDate().toDateString() == this.order.date.toDate().toDateString()) {
                this.pickupDay = days.length - 1;
                console.log("setting pickupday to " + this.pickupDay);
            }
        }
        return days;
    };
    EditOrderComponent.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('orders').doc(this.orderId).update(tslib_1.__assign({}, this.order, { date: this.pickupDays[this.pickupDay].date }))];
                    case 1:
                        _a.sent();
                        this.router.navigate(['/settings']);
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditOrderComponent;
}());
export { EditOrderComponent };
