import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { StripeService } from '../../services/stripe.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import * as moment from 'moment-timezone';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';
var BasicSettingsComponent = /** @class */ (function () {
    function BasicSettingsComponent(afa, aff, afs, cd, stripe, userService, config, router) {
        this.afa = afa;
        this.aff = aff;
        this.afs = afs;
        this.cd = cd;
        this.stripe = stripe;
        this.userService = userService;
        this.config = config;
        this.router = router;
        this.id = "";
        this.customer = {};
        this.cancelRequest = false;
        this.cardHandler = this.onChange.bind(this);
        this.errors = [];
        this.paymentErrors = [];
        this.cancelErrors = [];
        this.success = false;
        this.paymentSuccess = false;
        this.affiliates = [];
        this.orders = [];
    }
    BasicSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.afs.collection('orders', function (ref) { return ref.where("customerId", "==", _this.afa.auth.currentUser.uid).where("date", ">", new Date()); }).snapshotChanges().subscribe(function (snapshot) {
            _this.orders = snapshot.map(function (d) { return tslib_1.__assign({ id: d.payload.doc.id, moment: moment(d.payload.doc.data()['date'].seconds * 1000) }, d.payload.doc.data()); });
        });
        this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).valueChanges().subscribe(function (customer) {
            if (!customer)
                return;
            _this.id = _this.afa.auth.currentUser.uid;
            if (customer.status == "abandoned" || customer.status == "hidden") {
                if (_this.userService.isInServiceArea(customer)) {
                    _this.router.navigate(['/signup/step2']);
                }
                else {
                    _this.router.navigate(['signup', 'coming-soon']);
                }
            }
            if (!customer.affiliate) {
                customer.affiliate = null;
            }
            if (!customer.scent) {
                customer.scent = "None";
            }
            if (!customer.waterTemp) {
                customer.waterTemp = "Cold";
            }
            _this.customer = customer;
        });
        this.afs.collection('affiliates').snapshotChanges().subscribe(function (snapshot) {
            _this.affiliates = snapshot.map(function (d) { return tslib_1.__assign({ id: d.payload.doc.id }, d.payload.doc.data()); });
        });
    };
    BasicSettingsComponent.prototype.ngAfterViewInit = function () {
        this.card = this.stripe.elements.create('card', { style: {
                base: {
                    lineHeight: '21px'
                }
            }
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    };
    BasicSettingsComponent.prototype.ngOnDestroy = function () {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    };
    BasicSettingsComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.paymentErrors.push(error.message);
        }
        else {
            this.paymentErrors = [];
        }
        this.cd.detectChanges();
    };
    BasicSettingsComponent.prototype.updateCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, token, error;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.paymentErrors = [];
                        this.paymentSuccess = false;
                        return [4 /*yield*/, this.stripe.client.createToken(this.card)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (!error) return [3 /*break*/, 2];
                        this.paymentErrors.push(error.message);
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ stripeToken: token })];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.aff.functions.httpsCallable('updateCustomerCardFromToken')()];
                    case 4:
                        _b.sent();
                        this.paymentSuccess = true;
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BasicSettingsComponent.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fields, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.success = false;
                        if (!this.validate()) return [3 /*break*/, 2];
                        fields = {
                            firstName: this.customer.firstName,
                            lastName: this.customer.lastName,
                            instructions: this.customer.instructions,
                            phone: this.customer.phone,
                            scent: this.customer.scent,
                            waterTemp: this.customer.waterTemp,
                            reminders: !!this.customer.reminders,
                            notifications: !!this.customer.notifications,
                            softener: !!this.customer.softener,
                            affiliate: this.customer.affiliate,
                        };
                        return [4 /*yield*/, this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update(fields)];
                    case 1:
                        result = _a.sent();
                        this.success = true;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BasicSettingsComponent.prototype.requestCancellation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cancelDate;
            return tslib_1.__generator(this, function (_a) {
                if (this.cancelRequest) {
                    cancelDate = new Date();
                    this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({ cancelRequested: true, cancelDate: cancelDate });
                }
                else {
                    this.cancelErrors.push("You must confirm you want to cancel service using the provided checkbox.");
                }
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.skipService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                    skipCount: 1,
                });
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.unskipService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                    skipCount: 0,
                });
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.pauseService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                    paused: true,
                    pausedAt: new Date()
                });
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.unpauseService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                    paused: false,
                    unpausedAt: new Date()
                });
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.canSkip = function () {
        var date = moment().tz("America/Denver");
        if (date.day() == this.customer.laundryday)
            return false;
        return true;
    };
    BasicSettingsComponent.prototype.uncancel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('customers').doc(this.afa.auth.currentUser.uid).update({
                    cancelRequested: firebase.firestore.FieldValue.delete(),
                    cancelDate: firebase.firestore.FieldValue.delete()
                });
                return [2 /*return*/];
            });
        });
    };
    BasicSettingsComponent.prototype.validate = function () {
        return true;
    };
    return BasicSettingsComponent;
}());
export { BasicSettingsComponent };
