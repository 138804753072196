import * as i0 from "@angular/core";
var FacebookService = /** @class */ (function () {
    function FacebookService() {
    }
    FacebookService.prototype.trackPurchase = function () {
        fbq('track', 'Purchase');
    };
    FacebookService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FacebookService_Factory() { return new FacebookService(); }, token: FacebookService, providedIn: "root" });
    return FacebookService;
}());
export { FacebookService };
