import * as tslib_1 from "tslib";
import { CanActivate, Router, CanLoad } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, tap, mergeMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
var StaffGuard = /** @class */ (function () {
    function StaffGuard(af, afs, router) {
        this.af = af;
        this.afs = afs;
        this.router = router;
        this.staff = null;
        this.cachedUId = null;
    }
    // TODO: key this off of a flag in users instead of a match pattern
    StaffGuard.prototype.canActivate = function () {
        var _this = this;
        return this.isStaff().pipe(tap(function (staff) {
            if (!staff) {
                _this.router.navigate(['/']);
            }
        }));
    };
    StaffGuard.prototype.isStaff = function () {
        var _this = this;
        return this.af.authState.pipe(take(1), mergeMap(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var doc;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user) {
                            return [2 /*return*/, false];
                        }
                        if (!(this.staff === null || this.cachedUId !== user.uid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.afs.collection('employees').doc(user.uid).ref.get()];
                    case 1:
                        doc = _a.sent();
                        if (doc.exists) {
                            this.staff = true;
                        }
                        else {
                            this.staff = false;
                        }
                        this.cachedUId = user.uid;
                        _a.label = 2;
                    case 2: return [2 /*return*/, this.staff];
                }
            });
        }); }));
    };
    StaffGuard.prototype.canLoad = function () {
        return this.isStaff();
    };
    return StaffGuard;
}());
export { StaffGuard };
