import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var StripeService = /** @class */ (function () {
    function StripeService() {
        this.client = Stripe(environment.stripe.publicKey);
        this.elements = this.client.elements();
    }
    StripeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StripeService_Factory() { return new StripeService(); }, token: StripeService, providedIn: "root" });
    return StripeService;
}());
export { StripeService };
