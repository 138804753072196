import { AngularFirestore } from '@angular/fire/firestore';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var UserService = /** @class */ (function () {
    function UserService(afs) {
        this.afs = afs;
    }
    UserService.prototype.isInServiceArea = function (argument) {
        var zipcode;
        if (typeof argument.zipcode == "string") {
            zipcode = argument.zipcode;
        }
        else {
            zipcode = argument;
        }
        if (!zipcode)
            return true;
        if (/^\s*(80238|80207|80218|80220|80206|80246|80022|80230|80203|80209|80224|80211|80239|80249)(-(\d*))?\s*$/.test(zipcode)) {
            return true;
        }
        else {
            return false;
        }
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AngularFirestore)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
