import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { StripeService } from '../../services/stripe.service';
import { UserService } from '../../services/user.service';
import { FacebookService } from '../../services/facebook.service';
var Step2Component = /** @class */ (function () {
    function Step2Component(afAuth, afDb, router, cd, userService, stripe, facebook) {
        this.afAuth = afAuth;
        this.afDb = afDb;
        this.router = router;
        this.cd = cd;
        this.userService = userService;
        this.stripe = stripe;
        this.facebook = facebook;
        this.cardHandler = this.onChange.bind(this);
        this.city = "Denver";
        this.state = "Colorado";
        this.instructions = "";
        this.serviceType = "basic";
        this.bincount = 1;
        this.referral = "";
        this.affiliate = null;
        this.affiliates = [];
    }
    Step2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.referral = localStorage.getItem('referredBy');
        this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).valueChanges().subscribe(function (customer) {
            if (!_this.userService.isInServiceArea(customer)) {
                _this.router.navigate(['signup', 'coming-soon']);
            }
        });
        this.afDb.collection('affiliates').snapshotChanges().subscribe(function (snapshot) {
            _this.affiliates = snapshot.map(function (d) { return tslib_1.__assign({ id: d.payload.doc.id }, d.payload.doc.data()); });
        });
    };
    Step2Component.prototype.ngAfterViewInit = function () {
        this.card = this.stripe.elements.create('card', { style: {
                base: {
                    lineHeight: '21px'
                }
            }
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    };
    Step2Component.prototype.ngOnDestroy = function () {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    };
    Step2Component.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.errors.push(error.message);
        }
        else {
            this.errors = [];
        }
        this.cd.detectChanges();
    };
    Step2Component.prototype.validate = function () {
        this.errors = [];
        if (this.errors.length > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    Step2Component.prototype.saveContact = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, token, error;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.validate()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.stripe.client.createToken(this.card)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (error) {
                            this.errors.push(error);
                        }
                        else {
                            if (token.card.funding == "prepaid") {
                                // don't accept prepaid cards
                                this.errors.push("We don't support prepaid cards. Please use a debit or credit card.");
                            }
                            else {
                                this.afDb.collection('customers').doc(this.afAuth.auth.currentUser.uid).update({
                                    serviceType: this.serviceType,
                                    bincount: +this.bincount,
                                    affiliate: this.affiliate,
                                    city: this.city,
                                    state: this.state,
                                    instructions: this.instructions,
                                    referredBy: this.referral,
                                    stripeToken: token,
                                    reminders: true,
                                    status: "applicant",
                                }).then(function (result) {
                                    _this.facebook.trackPurchase();
                                    window.gtag('event', 'sign_up', { method: 'E-Mail' });
                                    window.gtag('event', 'conversion', { 'send_to': 'AW-799654185/s4Q9COf6wJEBEKmCp_0C' });
                                    _this.router.navigate(['signup', 'thankyou']);
                                });
                            }
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return Step2Component;
}());
export { Step2Component };
